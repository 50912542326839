<template>
  <div>
    <start-date-warning-modal :show="showLeaveStartDateWarning" @noClicked="rejectStartDate" @yesClicked="showLeaveStartDateWarning = false; setEndDate()"></start-date-warning-modal>
    <fieldset v-bind:disabled="isFormDisabled">
      <div>
        <div v-if="request.id" class="clearfix">
          <div class="float-right">
            <span class="col-md-3">status:</span>
            <span class="font-weight-bold" v-bind:class="{ 'text-danger': request.status == Enums.RequestStatus.Cancelled.value || request.status == Enums.RequestStatus.Rejected.value,  'text-success': request.status == Enums.RequestStatus.Approved.value, 'text-warning': request.status == Enums.RequestStatus.Returned.value }">{{ request.status | toRequestStatusDescription }}</span>
          </div>
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3"><span class="font-weight-bold text-danger">*</span>start date</label>
          <input type="date" class="form-input form-control col-md-2" id="startDate" v-model="request.startDate" :min="minimumStartDate" :max="maximumStartDate" required />
          <span> {{ request.startDate | toLongDateString }}</span>
          <div class="col-md-4 invalid-feedback">
            please select a valid date.
          </div>
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3"><span class="font-weight-bold text-danger">*</span>number of days</label>
          <input type="number" class="form-input form-control col-md-1" v-model="request.duration" min="1" :max="maximumDuration" v-bind:readonly="maximumDuration == 1" required />
          <div class="invalid-feedback col-md-6">
            Please input a valid number <span class="font-weight-bold">(max:  {{ maximumDuration }})</span> for the duration of the personal business day leave.
          </div>
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3"><span class="font-weight-bold text-danger">*</span>local holidays</label>
          <input type="number" class="form-input form-control col-md-1" v-model="request.holidays" readonly />
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3">end date</label>
          <input type="date" class="form-control col-md-2" v-model="request.endDate" readonly required />
          <span> {{ request.endDate | toLongDateString }}</span>
        </div>
        <div class="form-group row mt-4">
          <label class="col-md-3">resumption date</label>
          <input type="date" class="form-control col-md-2" v-model="request.resumptionDate" readonly required />
          <span> {{ request.resumptionDate | toLongDateString }}</span>
        </div>
        <!--<div class="form-group row mt-4">
    <label class="col-md-3">contact phone #</label>
    <input type="text" class="form-control col-md-2" v-model="request.contactPhoneNumber" />
  </div>
  <div class="form-group row mt-4">
    <label class="col-md-3">contact address</label>
    <textarea class="form-control col-md-5" rows="3" v-model="request.contactAddress"></textarea>
  </div>-->
        <div class="form-group row mt-4">
          <label class="col-md-3">
            <!--<span class="font-weight-bold text-danger">*</span>-->
            remarks
          </label>
          <textarea class="form-input form-control col-md-5" rows="4" v-model="request.remarks"></textarea>
        </div>
        <!--<div class="form-group row mt-4">
    <span class="col-md-3">approver</span>
    <span class="col-md-7">
      {{ employeeInfo.supervisorName }}
      <template v-if="employeeInfo.alternateSupervisorName">
        &nbsp;/&nbsp;{{ employeeInfo.alternateSupervisorName }}
      </template>
      <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-question-circle-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.57 6.033H5.25C5.22 4.147 6.68 3.5 8.006 3.5c1.397 0 2.673.73 2.673 2.24 0 1.08-.635 1.594-1.244 2.057-.737.559-1.01.768-1.01 1.486v.355H7.117l-.007-.463c-.038-.927.495-1.498 1.168-1.987.59-.444.965-.736.965-1.371 0-.825-.628-1.168-1.314-1.168-.901 0-1.358.603-1.358 1.384zm1.251 6.443c-.584 0-1.009-.394-1.009-.927 0-.552.425-.94 1.01-.94.609 0 1.028.388 1.028.94 0 .533-.42.927-1.029.927z">
          <title>DOA and Back-To-Back is automatically applied</title>
        </path>
      </svg>
    </span>
  </div>-->
      </div>
    </fieldset>
  </div>
</template>

<style scoped lang="scss">
  fieldset {
    border: 0;
  }
</style>

<script>
  import Vue from 'vue'
  import { mapState } from 'vuex'

  import Enums from '@/utils/enums'

  import isSameDay from 'date-fns/isSameDay'
  import { toLongDateString, fromServerDate, getLeaveDates, isLeaveStartDateLessThanCurrentDate } from '@/utils/date'
  import { toRequestStatusDescription } from '@/filters/enum'

  import LeaveRequestEntryFormMixin from '@/mixins/LeaveRequestEntryFormMixin'

  import StartDateWarningModal from '@/components/leaveRequests/StartDateWarningModal'

  export default {
    name: 'personal-business-day-entry',
    components: {
      StartDateWarningModal
    },
    mixins: [LeaveRequestEntryFormMixin],
    props: {
      request: {
        type: Object,
        required: true,
      },
      employeeInfo: {
        type: Object,
        required: true,
      },
      minimumStartDate: {
        type: String,
        required: true,
      },
      additionalData: {
        type: Object,
        required: true,
      },
      isFormDisabled: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data: () => ({
      showLeaveStartDateWarning: false,
      //minimumStartDate: fromServerDate(new Date(new Date().getFullYear(), 0, 1)),
      maximumStartDate: fromServerDate(new Date(new Date().getFullYear(), 11, 31)),
      Enums: Enums
    }),
    computed: {
      ...mapState({
        appSettings: state => state.applicationSettingsModule.appSettings,
        holidays: state => state.publicHolidayModule.publicHolidays,
      }),
      maximumDuration() {
        return this.appSettings.personalBusinessDays.yearlyTotalDays - this.additionalData.numberOfDaysTaken
      }
    },
    watch: {
      maximumDuration: {
        immediate: true,
        handler(newValue, oldValue) {
          if (newValue == 1) {
            this.request.duration = newValue
          }
          else if (!this.request.id) {
            this.request.duration = null
          }
        }
      },
      'request.duration'() {
        this.setEndDate()
      }
    },
    filters: {
      toLongDateString,
      toRequestStatusDescription
    },
    methods: {
      setEndDate() {
        if (this.request.startDate && this.request.duration) {
          var dates = getLeaveDates(this.request.startDate, this.request.duration, false, false, this.holidayDates)
          this.request.endDate = dates.endDate
          this.request.resumptionDate = dates.resumptionDate
          this.request.holidays = dates.numberOfHolidays
        }
      }
    },
    mounted() {
      const self = this
      const startDateElement = document.querySelector('#startDate');
      startDateElement.addEventListener('change', (event) => {
        let selectedDate = new Date(event.target.value)
        var day = new Date(selectedDate).getUTCDay()
        let startsOnHoliday = self.holidayDates.filter((holiday) => isSameDay(holiday, selectedDate)).length
        if ([6, 0].includes(day) || startsOnHoliday) {
          event.preventDefault();
          alert('Weekends / public holidays not allowed');
          event.target.value = '';
          self.request.startDate = null
          self.request.holidays = null
          self.request.endDate = null
          self.request.resumptionDate = null
          startDateElement.click()
          return
        }
        Vue.nextTick(() => {
          if (self.request.startDate) {
            if (isLeaveStartDateLessThanCurrentDate(self.request.startDate)) {
              self.showLeaveStartDateWarning = true
            }
            else {
              self.setEndDate()
            }
          }
        })
      })
    }
  }
</script>
