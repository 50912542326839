<script>
  import store from '@/store'

  import compareAsc from 'date-fns/compareAsc'
  import Enums from '@/utils/enums'

  export default {
    data: () => ({
      selectedRequestToAction: null,
      selectedAction: Enums.Actions.Approve,
      nextRequestStatus: null,
      actionComment: null,
      showSpinner: false,
      showApprovalOrRejectionModal: false,
      isActioningRequest: false,
      actions: Enums.Actions,
      selectedActionText: '',
      approvalOrRejectionErrorMessage: ''
    }),
    methods: {
      canEditRequest(request) {
        if ([Enums.RequestStatus.Cancelled.value, Enums.RequestStatus.Rejected.value].includes(request.status))
          return false
        const today = new Date()
        const requestStartDate = Date.parse(request.startDate)
        switch (request.requestType) {
          case Enums.RequestType.SickLeave.value:
          case Enums.RequestType.MaternityLeave.value:
            return (this.currentUser.isAdmin || [request.requestedById.toLowerCase()].includes(this.currentUser.id.toLowerCase())) && request.employeeId.toLowerCase() != this.currentUser.id.toLowerCase()
          case Enums.RequestType.VacationDaysRecall.value:
          case Enums.RequestType.HolidayNotTaken.value:
          case Enums.RequestType.CashInLieuOfVacation.value:
          case Enums.RequestType.BuyBack.value:
          case Enums.RequestType.VacationDaysCarryOver.value:
          case Enums.RequestType.AdjustmentAddition.value:
          case Enums.RequestType.AdjustmentRemoval.value:
            return false
          case Enums.RequestType.PersonalBusinessDays.value:
          case Enums.RequestType.PaternityLeave.value:
          case Enums.RequestType.CompassionateLeave.value:
          case Enums.RequestType.Vacation.value:
          case Enums.RequestType.RnR.value:
            switch (request.status) {
              case Enums.RequestStatus.Submitted.value:
              case Enums.RequestStatus.Edited.value:
              case Enums.RequestStatus.Returned.value:
                return this.currentUser.isAdmin || [request.requestedById.toLowerCase(), request.employeeId.toLowerCase()].includes(this.currentUser.id.toLowerCase())
              case Enums.RequestStatus.Approved.value:
                return compareAsc(requestStartDate, today) !== -1 && (this.currentUser.isAdmin || [request.requestedById.toLowerCase(), request.employeeId.toLowerCase()].includes(this.currentUser.id.toLowerCase()))
            }
          case Enums.RequestType.StudyLeave.value:
          case Enums.RequestType.LeaveOfAbsence.value:
            switch (request.status) {
              case Enums.RequestStatus.Submitted.value:
              case Enums.RequestStatus.Edited.value:
              case Enums.RequestStatus.Returned.value:
              case Enums.RequestStatus.PendingHRDirector.value:
              case Enums.RequestStatus.Approved.value:
                return this.currentUser.isAdmin || [request.requestedById.toLowerCase(), request.employeeId.toLowerCase()].includes(this.currentUser.id.toLowerCase())
              default:
                return false
            }
          //return this.currentUser.isAdmin || (request.status != Enums.RequestStatus.Rejected.value && (request.requestedById == this.currentUser.id.toLowerCase() || request.employeeId == this.currentUser.id.toLowerCase()))
          //  || (request.status == Enums.RequestStatus.Returned.value && (compareAsc(requestStartDate, today) == 1))
        }
        return false
      },
      canApproveRequest(request) {
        if ([Enums.RequestStatus.Cancelled.value, Enums.RequestStatus.Rejected.value].includes(request.status) || this.currentUser.id.toLowerCase() == request.employeeId.toLowerCase())
          return false
        switch (request.requestType) {
          case Enums.RequestType.AdjustmentAddition.value:
          case Enums.RequestType.AdjustmentRemoval.value:
            return false
          case Enums.RequestType.PersonalBusinessDays.value:
          case Enums.RequestType.CompassionateLeave.value:
          case Enums.RequestType.PaternityLeave.value:
          case Enums.RequestType.SickLeave.value:
          case Enums.RequestType.MaternityLeave.value:
          case Enums.RequestType.RnR.value:
          case Enums.RequestType.Vacation.value:
            return ((request.status == Enums.RequestStatus.Submitted.value || request.status == Enums.RequestStatus.Edited.value) && (this.currentUser.id.toLowerCase() == request.intendedApproverId.toLowerCase() || (this.currentUser.alternateEmployeeId && this.currentUser.alternateEmployeeId.toLowerCase() == request.intendedApproverId.toLowerCase()) || this.currentUser.doas.includes(request.intendedApproverId.toLowerCase()))) && ![/*request.requestedById.toLowerCase(), */request.employeeId.toLowerCase()].includes(this.currentUser.id.toLowerCase())
          case Enums.RequestType.StudyLeave.value:
          case Enums.RequestType.LeaveOfAbsence.value:
            return this.currentUser.roleRights && request.intendedApproverRoleRight && ((this.currentUser.roleRights & request.intendedApproverRoleRight) == request.intendedApproverRoleRight)
          case Enums.RequestType.VacationDaysRecall.value:
          case Enums.RequestType.HolidayNotTaken.value:
          case Enums.RequestType.CashInLieuOfVacation.value:
          case Enums.RequestType.BuyBack.value:
          case Enums.RequestType.VacationDaysCarryOver.value:
            return ((request.status == Enums.ChangeRequestStatus.Submitted.value) && (this.currentUser.id.toLowerCase() == request.intendedApproverId.toLowerCase() || (this.currentUser.alternateEmployeeId && this.currentUser.alternateEmployeeId.toLowerCase() == request.intendedApproverId.toLowerCase()) || this.currentUser.doas.includes(request.intendedApproverId.toLowerCase()))) && ![/*request.requestedById.toLowerCase(), */request.employeeId.toLowerCase()].includes(this.currentUser.id.toLowerCase())
          //if ((self.currentUser.userRightss & Enums.UserRights.EditAllData.value) === Enums.UserRights.EditAllData.value)
            //return (request.status == Enums.RequestStatus.Submitted.value || request.status == Enums.RequestStatus.Edited.value) && (request.intendedArroverId == this.currentUser.id.toLowerCase() || request.intendedApproverRole == this.currentUser.roleName)
        }
        return false
      },
      canCancelRequest(request) {
        //if ([Enums.RequestStatus.Cancelled.value, Enums.RequestStatus.Rejected.value].includes(request.status))
        //  return false
        switch (request.requestType) {
          case Enums.RequestType.AdjustmentAddition.value:
          case Enums.RequestType.AdjustmentRemoval.value:
            return false
          case Enums.RequestType.RnR.value:
            if (request.allowance.status >= Enums.AllowancePaymentStatus.Processed.value) {
              return false
            }
          case Enums.RequestType.PersonalBusinessDays.value:
          case Enums.RequestType.CompassionateLeave.value:
          case Enums.RequestType.PaternityLeave.value:
          case Enums.RequestType.SickLeave.value:
          case Enums.RequestType.MaternityLeave.value:
          case Enums.RequestType.StudyLeave.value:
          case Enums.RequestType.LeaveOfAbsence.value:
          case Enums.RequestType.Vacation.value:
            return this.canEditRequest(request)
          case Enums.RequestType.VacationDaysRecall.value:
          case Enums.RequestType.HolidayNotTaken.value:
          case Enums.RequestType.CashInLieuOfVacation.value:
          case Enums.RequestType.BuyBack.value:
          case Enums.RequestType.VacationDaysCarryOver.value:
            if (!Enums.ChangeRequestStatus.Approved.value && ((this.currentUser.isAdmin || [request.requestedById.toLowerCase(), request.employeeId.toLowerCase()].includes(this.currentUser.id.toLowerCase())) && ![Enums.ChangeRequestStatus.Cancelled.value, Enums.ChangeRequestStatus.Rejected.value].includes(request.status))) {
              return true
            }
            return this.canEditRequest(request)
        }
      },
      canRejectRequest(request) {
        switch (request.requestType) {
          case Enums.RequestType.AdjustmentAddition.value:
          case Enums.RequestType.AdjustmentRemoval.value:
            return false
          case Enums.RequestType.RnR.value:
            if (request.allowance.status >= Enums.AllowancePaymentStatus.Processed.value) {
              return false
            }
          case Enums.RequestType.SickLeave.value:
          case Enums.RequestType.MaternityLeave.value:
          case Enums.RequestType.PaternityLeave.value:
          case Enums.RequestType.CompassionateLeave.value:
          case Enums.RequestType.PersonalBusinessDays.value:
          case Enums.RequestType.StudyLeave.value:
          case Enums.RequestType.LeaveOfAbsence.value:
          case Enums.RequestType.Vacation.value:
          case Enums.RequestType.VacationDaysRecall.value:
          case Enums.RequestType.HolidayNotTaken.value:
          case Enums.RequestType.CashInLieuOfVacation.value:
          case Enums.RequestType.BuyBack.value:
          case Enums.RequestType.VacationDaysCarryOver.value:
            return this.canApproveRequest(request)
        }
      },
      canReturnRequest(request) {
        switch (request.requestType) {
          case Enums.RequestType.StudyLeave.value:
          case Enums.RequestType.LeaveOfAbsence.value:
            return (this.canApproveRequest(request) || this.currentUser.isAdmin) && ![request.requestedById.toLowerCase(), request.employeeId.toLowerCase()].includes(this.currentUser.id.toLowerCase())
          case Enums.RequestType.VacationDaysRecall.value:
          case Enums.RequestType.HolidayNotTaken.value:
          case Enums.RequestType.CashInLieuOfVacation.value:
          case Enums.RequestType.BuyBack.value:
          case Enums.RequestType.VacationDaysCarryOver.value:
          case Enums.RequestType.AdjustmentAddition.value:
          case Enums.RequestType.AdjustmentRemoval.value:
            return false
          case Enums.RequestType.PaternityLeave.value:
          case Enums.RequestType.CompassionateLeave.value:
          case Enums.RequestType.PersonalBusinessDays.value:
          case Enums.RequestType.SickLeave.value:
          case Enums.RequestType.MaternityLeave.value:
          case Enums.RequestType.Vacation.value:
            return this.canApproveRequest(request)
          case Enums.RequestType.RnR.value:
            return this.canApproveRequest(request) || this.canProcessRequest(request)
        }
      },
      canProcessRequest(request) {
        if (request.requestType === Enums.RequestType.RnR.value) {
          if (request.status === Enums.RequestStatus.Cancelled.value || request.status === Enums.RequestStatus.Rejected.value || request.allowance.status >= Enums.AllowancePaymentStatus.Processed.value) {
            return false
          }
          const today = new Date()
          return request.status === Enums.RequestStatus.Approved.value && request.allowance.payMonth == today.getMonth() + 1 && this.currentUser.isExpatriateAdmin
        }
        return false;
      },
      editRequest(request) {
        this.$router.push({ name: `edit${request.requestType}Request`, params: { id: request.id } })
      },
      cancelRequest(request) {
        this.approvalOrRejectionErrorMessage = ''
        this.actionRequest(request, Enums.Actions.Cancel, Enums.RequestStatus.Cancelled)
      },
      approveRequest(request) {
        this.approvalOrRejectionErrorMessage = ''
        this.actionRequest(request, Enums.Actions.Approve, Enums.RequestStatus.Approved)
      },
      rejectRequest(request) {
        this.approvalOrRejectionErrorMessage = ''
        this.actionRequest(request, Enums.Actions.Reject, Enums.RequestStatus.Rejected)
      },
      returnRequest(request) {
        this.approvalOrRejectionErrorMessage = ''
        this.actionRequest(request, Enums.Actions.Return, Enums.RequestStatus.Returned)
      },
      processRequest(request) {
        this.actionRequest(request, Enums.Actions.Process, Enums.RequestStatus.Processed)
      },
      actionRequest(request, action, nextRequestStatus) {
        this.selectedRequestToAction = request
        this.selectedAction = action
        this.nextRequestStatus = nextRequestStatus
        this.selectedActionText = this.selectedAction.description + ' request'
        if ([Enums.RequestType.StudyLeave.value, Enums.RequestType.LeaveOfAbsence].includes(request.requestType) && request.status != Enums.RequestStatus.PendingHRDirector.value && this.selectedAction == Enums.Actions.Approve) {
          this.selectedActionText = 'review request'
        }
        this.approvalOrRejectionErrorMessage = ''
        this.showApprovalOrRejectionModal = true
      },
      onActionRequestConfirmed() {
        const self = this
        const requestForm = document.getElementById('actionForm')
        if (requestForm.checkValidity() === false) {
          requestForm.classList.add('was-validated');
        }
        else {
          self.isActioningRequest = true
          store.dispatch(self.selectedAction.actionName, { requestIds: [self.selectedRequestToAction.id], requestType: self.selectedRequestToAction.requestType, comment: self.actionComment })
            .then((requests) => {
              let message = `request ${self.selectedAction.postActionText} successfully`
              if ([Enums.RequestType.StudyLeave.value, Enums.RequestType.LeaveOfAbsence].includes(this.selectedRequestToAction.requestType) && this.selectedRequestToAction.status != Enums.RequestStatus.PendingHRDirector.value && this.selectedAction == Enums.Actions.Approve) {
                message = 'request reviewed successfully'
              }
              self.showSuccessMessage(message)
              self.selectedRequestToAction.status = requests[0].status
              self.selectedRequestToAction.intendedApproverId = requests[0].intendedApproverId
              self.selectedRequestToAction.intendedApproverRoleRight = requests[0].intendedApproverRoleRight
              //self.selectedRequestToAction.status = requests[0].status
              //self.selectedRequestToAction.status = requests[0].status//self.nextRequestStatus.value
              self.actionComment = null
              self.showApprovalOrRejectionModal = false
              self.selectedRequestToAction = null
            })
            .catch((error) => {
              self.approvalOrRejectionErrorMessage = error
              //self.showErrorMessage(error)
            }).finally(() => {
              self.isActioningRequest = false
            })
        }
      },
      onDismissAction() {
        if (!this.isActioningRequest) {
          this.actionComment = null
          this.showApprovalOrRejectionModal = false
        }
      },
    }
  }


</script>
