<script>
  import Vue from 'vue'
  import { mapState } from 'vuex'

  import { toLongDateString, getLeaveDates, isLeaveStartDateLessThanCurrentDate, fromServerDate, flattenHolidayDates } from '@/utils/date'
  import { toRequestStatusDescription } from '@/filters/enum'

  import StartDateWarningModal from '@/components/leaveRequests/StartDateWarningModal'

  export default {
    components: {
      StartDateWarningModal
    },
    props: {
      //id: {
      //  type: String,
      //  required: false,
      //  default: 'requestEntryForm'
      //},
      request: {
        type: Object,
        required: true,
      },
      additionalData: {
        type: Object,
        required: false,
      },
      isFormDisabled: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    data() {
      return {
        showLeaveStartDateWarning: false,
      }
    },
    computed: {
      ...mapState({
        holidays: state => state.publicHolidayModule.publicHolidays,
      }),
      holidayDates() {
        let holidays = []
        if (this.holidays && this.holidays.length) {
          this.holidays.forEach((holiday) => {
            const dates = flattenHolidayDates(fromServerDate(holiday.startDate), holiday.numberOfDays)
            dates.forEach((date) => {
              holidays.push(date)
            })
          })
        }
        return holidays
      },
    },
    watch: {
      'request.duration'() {
        if (!this.ignoreDurationWatch) {
          this.setEndDate()
        }
      }
    },
    filters: {
      toLongDateString,
      toRequestStatusDescription
    },
    methods: {
      rejectStartDate() {
        this.request.startDate = ''
        this.request.endDate = ''
        this.request.resumptionDate = ''
        this.showLeaveStartDateWarning = false
      },
      setEndDate() {
        if (this.request.startDate && this.request.duration) {
          var dates = getLeaveDates(this.request.startDate, this.request.duration, this.employeeInfo.isFieldEmployee, this.employeeInfo.isFieldEmployee, this.holidayDates)
          this.request.endDate = dates.endDate
          this.request.resumptionDate = dates.resumptionDate
          this.request.holidays = dates.numberOfHolidays
        }
      }
    },
    mounted() {
      const self = this
      const startDateElement = document.querySelector('#startDate');

      startDateElement.addEventListener('change', (event) => {
        Vue.nextTick(() => {
          if (self.request.startDate) {
            if (self.showLeaveStartDateWarning && isLeaveStartDateLessThanCurrentDate(self.request.startDate)) {
              self.showLeaveStartDateWarning = true
            }
            else {
              self.setEndDate()
            }
          }
        })
      });
    }
  }

</script>
