<template>
  <div id="personalBusinessDaysRequestView" class="text-left">
    <div class="row">
      <div class="col">
        <h4>{{ isEditPage ? 'edit personal business day entry' : isEditable ? 'personal business day entry': 'view personal business day entry' }}</h4>
        <transition name="slide-fade">
          <app-alert v-if="alert.message" :type="alert.type" :message="alert.message" @alertclose="closeMessage" />
        </transition>
        <ul class="error">
          <li v-for="error in errors">
            {{ error.message }}
          </li>
        </ul>
        <app-modal :show="showApprovalOrRejectionModal" :static="true" size="medium" :title="selectedAction.description + ' request'">
          <div>
            <transition name="slide-fade">
              <app-alert v-if="approvalOrRejectionErrorMessage" :type="errorAlertType" :message="approvalOrRejectionErrorMessage" @alertclose="approvalOrRejectionErrorMessage = ''" />
            </transition>
            <form id="actionForm" onsubmit="event.preventDefault();">
              <fieldset v-bind:disabled="isActioningRequest">
                <div class="form-group row mt-2">
                  <span class="font-weight-bold">comment</span>
                  <textarea v-model="actionComment" rows="5" class="form-control" v-bind:required="selectedAction !== actions.Approve"></textarea>
                  <div class="invalid-feedback">
                    Please enter comments for this action.
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
          <template v-slot:footer>
            <button class="btn btn-danger d-inline mr-2" @click.prevent="onDismissAction" v-bind:disabled="isActioningRequest">No</button>
            <button class="btn btn-primary d-inline mr-2" v-bind:class="{ spin: isActioningRequest }" @click.prevent="onActionRequestConfirmed" v-bind:disabled="isActioningRequest">Yes<span class="spinner"></span></button>
          </template>
        </app-modal>
        <fieldset v-if="!isCompleted" v-bind:disabled="disableFields">
          <employee-autocomplete v-if="isEditable && !isEditPage" v-show="currentUserCanRaiseRequestForOtherRegularEmployees" :items="filteredSearchedEmployees" id="emp_search" :sData="employeeInfo.fullName" v-bind:isShown="true" isRequired />
          <div>
            <employee-info-display :employeeInfo="employeeInfo" :request="request" :numberOfDaysTaken="additionalData.numberOfDaysTaken" :totalDays="appSettings.personalBusinessDays.yearlyTotalDays" v-if="employeeInfo.employeeId"></employee-info-display>
          </div>
        </fieldset>
      </div>
    </div>
    <!--<fieldset v-if="!isCompleted" v-bind:disabled="isSavingRequest">-->
    <div class="row" v-if="leaveRequestCanBeRaisedForEmployee || (request.id)">
      <div class="col">
        <form id="leaveEntryForm" onsubmit="event.preventDefault();" novalidate>
          <PersonalBusinessDayRequestEntryForm :request="request" :employeeInfo="employeeInfo" :minimumStartDate="minimumStartDate" :additionalData="additionalData" :isFormDisabled="disableFields" />
          <div class="m-2" v-if="request.id && isEditable">
            <h4>review comment</h4>
            <textarea class="form-control col-md-5 offset-3" rows="4" v-model="comment" required  v-bind:disabled="disableFields"></textarea>
          </div>
          <div class="form-group row mt-4" v-if="isEditable">
            <span class="col-md-3"></span>
            <employee-autocomplete label="approver" errorLabel="Please, select a valid approver" :items="searchedApprovers" id="supv_search" :sData="request.intendedApproverName" :employeeSearchClearedEvent="approverSearchClearedEvent" :employeeSearchStartedEvent="approverSearchStartedEvent" :employeeSelectedEvent="approverSelectedEvent" v-bind:isShown="true" isRequired :isDisabled="disableFields || isSearchingEmployees" />
            <span>
              <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-question-circle-fill" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM6.57 6.033H5.25C5.22 4.147 6.68 3.5 8.006 3.5c1.397 0 2.673.73 2.673 2.24 0 1.08-.635 1.594-1.244 2.057-.737.559-1.01.768-1.01 1.486v.355H7.117l-.007-.463c-.038-.927.495-1.498 1.168-1.987.59-.444.965-.736.965-1.371 0-.825-.628-1.168-1.314-1.168-.901 0-1.358.603-1.358 1.384zm1.251 6.443c-.584 0-1.009-.394-1.009-.927 0-.552.425-.94 1.01-.94.609 0 1.028.388 1.028.94 0 .533-.42.927-1.029.927z">
                  <title>DOA and Back-To-Back is automatically applied</title>
                </path>
              </svg>
            </span>
          </div>
          <!--<div class="m-2" v-if="request.id">
          <h4>review comment</h4>
          <textarea class="form-input form-control col-md-5 offset-3" rows="4" v-model="comment" required></textarea>
        </div>-->
        </form>
        <!--<div class="offset-3 mt-4">
        <button class="btn btn-danger d-inline mr-2" @click="resetData" v-bind:disabled="isSavingRequest">Cancel</button>
        <button class="btn btn-primary d-inline mr-2" v-bind:class="{ spin: isSavingRequest }" @click="submitRequest" v-bind:disabled="isSavingRequest">Submit<span class="spinner"></span></button>
      </div>-->
        <div class="offset-3 mt-4" v-if="isEditable">
          <button class="btn btn-danger d-inline mr-2" v-if="!isNewRequestPage && canCancelRequest(request)" @click="cancelRequest(request)" v-bind:disabled="isSavingRequest">Cancel</button>
          <button class="btn btn-primary d-inline mr-2" v-if="leaveRequestCanBeRaisedForEmployee" v-bind:class="{ spin: isSavingRequest }" @click="submitRequest" v-bind:disabled="isSavingRequest">Submit<span class="spinner"></span></button>
        </div>
        <div class="offset-3 mt-4" v-else-if="isReviewPage">
          <button class="btn btn-secondary d-inline mr-2" :style="{visibility: canEditRequest(request) ? 'visible' : 'hidden'}" @click="editRequest(request)">Edit</button>
          <button class="btn btn-primary d-inline mr-2" v-if="canApproveRequest(request)" @click="approveRequest(request)" title="Approve request">Approve</button>
          <button class="btn btn-danger d-inline mr-2" v-if="canCancelRequest(request)" @click="cancelRequest(request)" title="Cancel request">Cancel</button>
          <button class="btn btn-danger d-inline mr-2" v-else-if="canApproveRequest(request)" @click="rejectRequest(request)" title="Reject request">Reject</button>
          <button class="btn btn-warning d-inline mr-2" v-if="canApproveRequest(request)" @click="returnRequest(request)" title="Return request">Return</button>
        </div>
      </div>
    </div>
    <!--</fieldset>-->
    <div class="row" v-if="request.id">
      <div class="col">
        <h5>Action/Log History Details</h5>
        <RequestActionList :requestActions="request.requestActions"></RequestActionList>
      </div>
    </div>
    <div v-if="isCompleted && isNewRequestPage" class="text-right">
      <button class="btn btn-primary d-inline mr-2" @click="startNewRequest">New Request</button>
    </div>
  </div>
</template>

<style scoped lang="scss">

  .slide-fade-enter-active {
    transition: all .5s ease;
  }

  .slide-fade-leave-active {
    transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }

  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  .error {
    text-align: left;
    color: #ac0c0c;
    list-style: none;
  }

  fieldset {
    border: 0;
  }

  button {
    position: relative;
    transition: all 1s;
  }

  .spin {
    padding-left: 2.5em;
    display: block;
  }

  .spin .spinner {
    left: -.6em;
    top: .4em;
    width: 2.5em;
    display: block;
    position: absolute;
  }

  /* spinner animation */
  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }

  /* The actual spinner element is a pseudo-element */
  .spin .spinner::before {
    content: "";
    width: 1.5em; /* Size of the spinner */
    height: 1.5em; /* Change as desired */
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    border: solid .35em #000; /* Thickness/color of spinner track */
    border-bottom-color: #555; /* Color of variant spinner piece */
    animation: .8s linear infinite spinner; /* speed of spinner */
    transform: translate(-50%, -50%);
    will-change: transform;
  }

  /* optional, but it will affect the size if changed */
  *, *::before, *::after {
    box-sizing: border-box;
  }
</style>

<script>

  import store from '@/store'
  import { FETCH_PUBLICHOLIDAYS } from '@/store/action-type'

  import ErrorMessages from '@/utils/errors/messages'
  import Enums from '@/utils/enums'

  import { employeeService, personalBusinessDaysRequestService, leaveRequestService, maternityLeaveRequestService, studyLeaveRequestService, leaveOfAbsenceRequestService } from '@/services'

  import eventBus from '@/utils/eventBus'
  import Events from '@/utils/events'
  import { CLOSE_MODAL_EVENT } from '@/utils/constants'

  import { fromServerDate } from '@/utils/date'
  import isAfter from 'date-fns/isAfter'
  import addDays from 'date-fns/addDays'
  import differenceInDays from 'date-fns/differenceInDays'
  import max from 'date-fns/max'

  import EmployeeInfoDisplay from '@/components/others/EmployeeInfoDisplay'
  import PersonalBusinessDayRequestEntryForm from '@/components/personalBusinessDay/EntryForm'
  import RequestActionList from '@/components/leaveRequests/RequestActionList'

  import GeneralMixin from '@/mixins/GeneralMixin'
  import AccessControlMixin from '@/mixins/AccessControlMixin'
  import LeaveRequestMixin from '@/mixins/leaveRequest'
  import LeaveRequestActionsMixin from '@/mixins/leaveRequest/requestActions'

  export default {
    name: 'PersonalBusinessDayRequestView',
    components: {
      EmployeeInfoDisplay,
      PersonalBusinessDayRequestEntryForm,
      RequestActionList
    },
    mixins: [GeneralMixin, AccessControlMixin, LeaveRequestMixin, LeaveRequestActionsMixin],
    data: () => ({
      request: {
        id: null,
        startDate: '',
        endDate: '',
        resumptionDate: '',
        duration: null,
        holidays: null,
        intendedApproverId: '',
        intendedApproverName: '',
        status: 0,
        remarks: '',
        requestType: Enums.RequestType.PersonalBusinessDays.value,
        requestActions: []
      },
      employeeInfo: {
        employeeId: '',
        employeeNo: '',
        employmentDate: '',
        fullName: '',
        locationName: '',
        workScheduleName: '',
        employeeTypeName: '',
        supervisorName: '',
        alternateSupervisorName: '',
        isRegularEmployee: false,
        isFieldEmployee: false,
        isOfficeBasedEmployee: false,
        isRotationalEmployee: false,
        isShiftEmployee: false,
        isOnForeignService: false,
        isOverseas: false
      },
      additionalData: {
        employeeLocationHistory: [],
        employeeWorkScheduleHistory: [],
        lastMaternityLeave: null,
        lastStudyLeave: null,
        lastLeaveOfAbsence: null,
        numberOfDaysTaken: 0
      },
      comment: null,
      approvalOrRejectionErrorMessage: null,
    }),
    computed: {
      filteredSearchedEmployees() {
        if (this.searchedEmployees && this.searchedEmployees.length) {
          return this.searchedEmployees.filter(employee => employee.isRegularEmployee && employee.isOfficeBasedEmployee && !employee.isRotationalEmployee)
        }
        return []
      },
      minimumStartDate() {
        if (this.currentUser.isAdmin && this.employeeInfo.employeeId.toLowerCase() != this.currentUser.employeeId.toLowerCase()) {
          const currentYear = new Date().getFullYear();
          let firstDayInYear = new Date(currentYear, 0, 1, 0, 0, 0, 0)
          return fromServerDate(firstDayInYear)
        }
        else {
          let date = new Date()
          if (this.isViewPage) {
            date = this.request.startDate
          }
          else {
            const currentYear = new Date().getFullYear();
            let firstDayInYear = new Date(currentYear, 0, 1, 0, 0, 0, 0)

            date = max([firstDayInYear, addDays(new Date(this.employeeInfo.employmentDate), this.appSettings.personalBusinessDays.minimumNumberOfDaysWorkedBeforeEntitlement)])
            if (this.additionalData.lastStudyLeave) {
              const studyLeaveAllowedDate = addDays(Date.parse(this.additionalData.lastStudyLeave.actualResumptionDate), this.appSettings.personalBusinessDays.minimumNumberOfDaysWorkedBeforeEntitlement)
              date = max([date, studyLeaveAllowedDate])
            }
            if (this.additionalData.lastLeaveOfAbsence) {
              const loaAllowedDate = addDays(Date.parse(this.additionalData.lastLeaveOfAbsence.actualResumptionDate), this.appSettings.personalBusinessDays.minimumNumberOfDaysWorkedBeforeEntitlement)
              date = max([date, loaAllowedDate])
            }
            if (this.additionalData.lastMaternityLeave) {
              const matAllowedDate = addDays(Date.parse(this.additionalData.lastMaternityLeave.resumptionDate), this.appSettings.personalBusinessDays.minimumNumberOfDaysWorkedBeforeEntitlement)
              date = max([date, matAllowedDate])
            }
            if (this.additionalData.employeeLocationHistory.length > 1) {
              const previousLocation = this.additionalData.employeeLocationHistory[1]
              if (previousLocation.locationId == '029') {
                const currentLocation = this.additionalData.employeeLocationHistory[0]
                const newLocationEffectiveDate = addDays(Date.parse(currentLocation.effectiveDate), this.appSettings.personalBusinessDays.minimumNumberOfDaysWorkedBeforeEntitlement)
                date = max([date, newLocationEffectiveDate])
              }
            }
            if (this.additionalData.employeeWorkScheduleHistory.length > 1) {
              const previousWorkSchedule = this.additionalData.employeeWorkScheduleHistory[1]
              if (previousWorkSchedule.workScheduleId !== '') {
                const currentWorkSchedule = this.additionalData.employeeWorkScheduleHistory[0]
                const newWorkScheduleEffectiveDate = addDays(Date.parse(currentWorkSchedule.effectiveDate), this.appSettings.personalBusinessDays.minimumNumberOfDaysWorkedBeforeEntitlement)
                date = max([date, newWorkScheduleEffectiveDate])
              }
            }

            if (isAfter(firstDayInYear, date)) {
              date = firstDayInYear
            }
          }
          return fromServerDate(date)
        }
      },
      leaveRequestCanBeRaisedForEmployee() {
        if (this.isEditable && this.employeeInfo.employeeId) {
          const currentYear = new Date().getFullYear();
          var lastDayInYear = new Date(currentYear, 11, 31, 0, 0, 0, 0)
          const employmentDate = new Date(this.employeeInfo.employmentDate)
          if (this.isEditPage && !this.canEditRequest(this.request)) {
            this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.RequestCannotBeModifiedDueToCurrentState, this.request.requestType)
            return false
          }
          if (this.appSettings.personalBusinessDays.yearlyTotalDays - this.additionalData.numberOfDaysTaken < 1) {
            this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.CannotRaiseRequestBecauseYearlyTotalMet, this.request.requestType)
            return false
          }
          if (employmentDate.getFullYear() == currentYear && differenceInDays(lastDayInYear, employmentDate) <= this.appSettings.personalBusinessDays.minimumNumberOfDaysWorkedBeforeEntitlement) {
            this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.CannotRaiseRequestDueToEmployementDate, this.request.requestType)
            return false
          }
          if (!this.employeeInfo.isRegularEmployee) {
            this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.CannotRaiseRequestDueToEmployeeType, this.request.requestType)
            return false
          }
          if (this.employeeInfo.isFieldEmployee || !this.employeeInfo.isOfficeBasedEmployee || this.employeeInfo.isOnForeignService || this.employeeInfo.isOverseas || this.employeeInfo.isRotationalEmployee || this.employeeInfo.isShiftEmployee) {
            this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.CannotRaiseRequestDueToEmployeeTypeOrScheduleOrLocation, this.request.requestType)
            return false
          }
          if ((this.additionalData.lastStudyLeave && !this.additionalData.lastStudyLeave.actualResumptionDate) || (this.additionalData.lastLeaveOfAbsence && !this.additionalData.lastLeaveOfAbsence.actualResumptionDate)) {
            this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.RunningLeaveOfAbsenceOrStudyLeave, this.request.requestType)
            return false
          }
          if (this.numberOfDaysTaken >= this.appSettings.personalBusinessDays.yearlyTotalDays) {
            this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.CannotRaiseRequestBecauseYearlyTotalMet, this.request.requestType)
            return false
          }
          if (this.minimumStartDate) {
            if (isAfter(Date.parse(this.minimumStartDate), lastDayInYear)) {
              this.errorMessageIfRequestCannotBeRaisedForEmployee = ErrorMessages.getByErrorType(ErrorMessages.CannotRaiseRequestDueToMinimumStartDateNotCurrentYear, this.request.requestType)
              return false
            }
          }
          this.errorMessageIfRequestCannotBeRaisedForEmployee = ''
          return true
        }
        else {
          this.errorMessageIfRequestCannotBeRaisedForEmployee = ''
          return false
        }
      },
    },
    methods: {
      resetOtherData() {
        this.employeeInfo.employmentDate = ''
        this.employeeInfo.isOfficeBasedEmployee = false
        this.employeeInfo.isRotationalEmployee = false
        this.employeeInfo.isShiftEmployee = false
        this.employeeInfo.isOnForeignService = false
        this.employeeInfo.isOverseas = false
        this.comment = ''
        this.additionalData.employeeLocationHistory.splice(0, this.additionalData.employeeLocationHistory.length)
        this.additionalData.employeeWorkScheduleHistory.splice(0, this.additionalData.employeeWorkScheduleHistory.length)
        this.additionalData.lastStudyLeave = null
        this.additionalData.lastLeaveOfAbsence = null
        this.additionalData.lastMaternityLeave = null
        this.additionalData.numberOfDaysTaken = 0
      },
      fetchEmployeeData(employee) {
        const self = this
        const tasks = []
        eventBus.$emit(Events.LongOperationStarted, '')
        tasks.push(employeeService.getEmployee(employee.employeeId))
        tasks.push(personalBusinessDaysRequestService.getNumberOfDaysTaken(employee.employeeId, new Date().getFullYear()))
        tasks.push(employeeService.getEmployeeLocationHistory(employee.employeeId))
        tasks.push(employeeService.getEmployeeWorkScheduleHistory(employee.employeeId))
        tasks.push(studyLeaveRequestService.getLastStudyLeaveForEmployee(employee.employeeId))
        tasks.push(leaveOfAbsenceRequestService.getLastLeaveOfAbsenceForEmployee(employee.employeeId))
        tasks.push(maternityLeaveRequestService.getLastMaternityLeaveForEmployee(employee.employeeId))
        Promise.all(tasks).then((results) => {
          self.setData(results[0].data, results[1], results[2], results[3], results[4], results[5], results[6])
        }).catch((error) => {
          self.showErrorMessage(error)
        }).finally(() => {
          eventBus.$emit(Events.LongOperationCompleted)
        })
      },
      fetchExistingDataForView() {
        const self = this
        eventBus.$emit(Events.LongOperationStarted, '')
        leaveRequestService.getLeaveRequest(Enums.RequestType.PersonalBusinessDays.value, self.$route.params.id).then((request) => {
          const employee = request.employeeInfo
          let tasks = []
          tasks.push(personalBusinessDaysRequestService.getNumberOfDaysTaken(employee.employeeId, new Date().getFullYear()))
          Promise.all(tasks).then((results) => {
            const numberOfDaysTaken = results[0]
            self.setData(employee, numberOfDaysTaken - request.duration, null, null, null, null, null, request)
          }).catch((error) => {
            self.showErrorMessage(error)
          }).finally(() => {
            eventBus.$emit(Events.LongOperationCompleted)
          })
        }).catch((error) => {
          eventBus.$emit(Events.LongOperationCompleted)
          self.showErrorMessage(error)
        })
      },
      fetchExistingData() {
        const self = this
        eventBus.$emit(Events.LongOperationStarted, '')
        leaveRequestService.getLeaveRequest(Enums.RequestType.PersonalBusinessDays.value, self.$route.params.id).then((request) => {
          const employee = request.employeeInfo
          let tasks = []
          tasks.push(personalBusinessDaysRequestService.getNumberOfDaysTaken(employee.employeeId, new Date().getFullYear()))
          tasks.push(employeeService.getEmployeeLocationHistory(employee.employeeId))
          tasks.push(employeeService.getEmployeeWorkScheduleHistory(employee.employeeId))
          tasks.push(studyLeaveRequestService.getLastStudyLeaveForEmployee(employee.employeeId))
          tasks.push(leaveOfAbsenceRequestService.getLastLeaveOfAbsenceForEmployee(employee.employeeId))
          tasks.push(maternityLeaveRequestService.getLastMaternityLeaveForEmployee(employee.employeeId))
          Promise.all(tasks).then((results) => {
            const numberOfDaysTaken = results[0]
            self.setData(employee, numberOfDaysTaken - request.duration, results[1], results[2], results[3], results[4], results[4], request)
          }).catch((error) => {
            self.showErrorMessage(error)
          }).finally(() => {
            eventBus.$emit(Events.LongOperationCompleted)
          })
        }).catch((error) => {
          eventBus.$emit(Events.LongOperationCompleted)
          self.showErrorMessage(error)
        })
      },
      setData(employee, numberOfDaysTaken, employeeLocationHistory, employeeWorkScheduleHistory, lastStudyLeave, lastLeaveOfAbsence, lastMaternityLeave, request) {
        if (request) {
          this.setBaseRequestData(request)
        }
        else {
          this.request.intendedApproverId = employee.supervisorId
          this.request.intendedApproverName = employee.supervisorName
        }
        this.setEmployeeData(employee)
        this.employeeInfo.employmentDate = employee.employmentDate
        this.employeeInfo.isOfficeBasedEmployee = employee.isOfficeBasedEmployee
        this.employeeInfo.isRotationalEmployee = employee.isRotationalEmployee
        this.employeeInfo.isShiftEmployee = employee.isShiftEmployee
        this.employeeInfo.isOnForeignService = employee.isOnForeignService
        this.employeeInfo.isOverseas = employee.isOverseas

        this.additionalData.numberOfDaysTaken = numberOfDaysTaken

        if (employeeLocationHistory && employeeLocationHistory.length > 0) {
          for (const locationHistory of employeeLocationHistory) {
            this.additionalData.employeeLocationHistory.push(locationHistory)
          }
        }

        if (employeeWorkScheduleHistory && employeeWorkScheduleHistory.length > 0) {
          for (const workScheduleHistory of employeeWorkScheduleHistory) {
            this.additionalData.employeeWorkScheduleHistory.push(workScheduleHistory)
          }
        }

        this.additionalData.lastStudyLeave = lastStudyLeave
        this.additionalData.lastLeaveOfAbsence = lastLeaveOfAbsence
        this.additionalData.lastMaternityLeave = lastMaternityLeave

      }
    },
    beforeRouteEnter(to, from, next) {
      eventBus.$emit(Events.LongOperationStarted, '')
      const tasks = []
      tasks.push(store.dispatch(FETCH_PUBLICHOLIDAYS, new Date().getFullYear()))
      Promise.all(tasks).then(next(vm => {
        vm.closeMessage()
        vm.resetData()
        if (vm.isEditable && vm.$route.params.id) {
          vm.fetchExistingData()
        }
        else if (vm.$route.params.id) {
          vm.fetchExistingDataForView()
        }
        else {
          vm.pageIsFullyLoaded = true
          vm.isCompleted = false
          vm.fetchEmployeeData(vm.currentUser)
        }
      })).catch((error) => {
        next(vm => {
          vm.showErrorMessage(error)
          eventBus.$emit(Events.LongOperationCompleted)
        })
      }).finally(() => {
      })
    },
    mounted() {
      eventBus.$on(CLOSE_MODAL_EVENT, this.onDismissAction)
    },
    beforeDestroy() {
      eventBus.$off(CLOSE_MODAL_EVENT)
    }
  }
</script>
