<template>
  <div>
    <app-modal v-bind:closeOnEscape="false" title="leave start date" :show="show">
      <div>
        <p>
          start date is less than today! schedule will be regarded as taken once approved.
        </p>
        <p class="font-weight-bold">
          will you like to proceed with the selection?
        </p>
      </div>
      <template v-slot:footer>
        <button class="btn btn-danger d-inline mr-2" @click="noClicked">no</button>
        <button class="btn btn-primary d-inline mr-2" @click="yesClicked">yes</button>
      </template>
    </app-modal>
  </div>
</template>

<style scoped lang="scss">
</style>

<script>

  import AppModal from '@/components/ui/AppModal'

  export default {
    name: 'start-date-warning-modal',
    components: {
      AppModal
    },
    inject: ['eventBus'],
    provide() {
      return {
        eventBus: this.eventBus
      }
    },
    props: {
      show: {
        type: Boolean,
        required: false,
        default: false
      }
    },
    methods: {
      noClicked() {
        this.$emit('noClicked')
      },
      yesClicked(evt) {
        this.$emit('yesClicked')
      }
    }
  }
</script>
